import React from 'react'
import { Helmet } from 'react-helmet'

class Features extends React.Component {
    state = {

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    getMeta = () => {
        return (
            <Helmet>
                <title>Collaborative Travel Platform Features and Capabilities | Luna Travel Technology</title>
                <meta name="description" content="Luna Travel Technology's collaborative planning and booking platform boasts unique features including real-time collaboration, split payments for travel reservations, and an integrated booking engine." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <div className="page">
                {this.getMeta()}
                <div className="container-fluid">
                    <div className="row home-band back-dark">
                        <div className="col">
                            <h1>Collaborative Travel Technology</h1>
                            <p>Enterprise grade technology solves tomorrow's travel problems today.</p>
                        </div>
                    </div>

                    <div className="row feature-band">
                        <div className="card-deck">
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-group" />
                                    <h2 className="card-title">Made for groups</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    Travel happens together and we think travel planning should too.
                                    Our platform puts collaboration front and center.
                                    Multiple people can plan and book trips together online at the same time.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-like" />
                                    <h2 className="card-title">Collaboration first</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    Collaborative tools help people make travel plans with each other.
                                    Updates happen in real time so everyone can see what's happening.
                                    Feedback is made simple through voting and comments.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-payment" />
                                    <h2 className="card-title">Integrated booking</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    What sets Luna's platform apart from other planners is the integrated booking.
                                    We connect with travel vendors and suppliers to bring up-to-date pricing and availability. 
                                    </p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-lighthouse" />
                                    <h2 className="card-title">Whole itinerary discovery</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    Luna's interactive itinerary covers the entire trip &mdash;
                                    from where to go, where to stay, what to do, and how to get around.
                                    We make it easy to work with your custom content and pricing. 
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-compass" />
                                    <h2 className="card-title">Flexible Content</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    Our content engine works with any provider to cover everything from popular spots to hidden gems.
                                    Tap into our existing global coverage or bring your own content.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-spa" />
                                    <h2 className="card-title">Simplify sales</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    No more PDFs, endless email chains or missed phone calls.
                                    Luna's platform closes the distance between you and your clients.
                                    Interact with your clients directly and save time and money.
                                    </p>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-bell" />
                                    <h2 className="card-title">The new conceirge</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    People find inspiration everywhere. 
                                    Work with your suppliers and partners to expose recommendations directly on our product.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-suitcase_b" />
                                    <h2 className="card-title">Travel happens together</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    At its core Luna is about making travel great for everyone.
                                    </p>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-top">
                                    <i className="ta-icon-agent" />
                                    <h2 className="card-title">Privileged access</h2>
                                </div>
                                <div className="card-body">
                                    <p className="card-text">
                                    Data rich environment for you and your team to know how people travel with each other.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Features
