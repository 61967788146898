import React from 'react'
import WebfontLoader from '@dr-kobros/react-webfont-loader'
import { Switch, Route } from 'react-router-dom'

import Nav from './layout/nav'
import Footer from './layout/footer'
import Home from './pages/home'
import Features from './pages/features'
import Uses from './pages/use-cases'
import Projects from './pages/projects'
import About from './pages/about'
import Contact from './pages/contact'

class App extends React.Component {
    state = {

    }

    render = () => {
        const config = { google: {families:  ['Lato:300,400']}}
        return (
            <WebfontLoader config={config}>
                <div className="ltt">
                    <Nav />
                    <Switch>
                        <Route path="/features" component={Features} />
                        <Route path="/use-cases" component={Uses} />
                        <Route path="/projects" component={Projects} />
                        <Route path="/about" component={About} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/" component={Home} />
                    </Switch>
                    <Footer />
                </div>
            </WebfontLoader>
        )
    }
}

export default App
