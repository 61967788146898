import React from 'react'
import { Helmet } from 'react-helmet'

class Team extends React.Component {
    state = {

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    getMeta = () => {
        return (
            <Helmet>
                <title>About Luna Travel Technology Inc.</title>
                <meta name="description" content="Luna Travel Technology is a collaborative travel planning and booking platform. We offer white label planning and booking solutions for travel companies." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <div className="page">
                <div className="container-fluid">
                    <div className="row home-band back-dark">
                        <div className="col">
                            <h1>About Luna Travel Technology, Inc.</h1>
                            <p>Travel Happens Together</p>
                        </div>
                    </div>

                    <div className="row home-band">
                        <div className="col-md-6 offset-md-3 text-left">
                            <h2>Collaborative Travel Planning and Booking Platform</h2>
                            <p>
                                Luna Travel Technology is a collaborative travel planning and booking platform.
                                Our products help travelers make better informed purchases by activating the people, places and resources that inform travel plans.
                            </p>
                            <p>
                                Planning and purchasing travel is a broken process.
                                The average traveler visits nearly 140 websites before making a travel purchase and there are limited resources to help people manage, share and execute their travel plans.
                                When traveling with other people, the problem is exacerbated in an effort to coordinate schedules, collaborate on decisions, and figure out who pays for what.
                            </p>
                            <p>
                                At Luna, we know that travel happens together and we think planning and booking should too.
                                Our collaborative technology enables two or more people to plan a trip together at the same time.
                                Built on our travel technology platform, we have developed an interactive itinerary that encompasses the entire trip and offers innovative solutions including split payments and group itinerary management.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team
