import React from 'react'
import { Helmet } from 'react-helmet'

import lunaImg from '../../assets/img/products/lunamoons-screenshot.jpg'
import packImg from '../../assets/img/products/pack-screenshot.jpg'
import tripsbyImg from '../../assets/img/products/tripsbyluna-screenshot.jpg'
import tripsappImg from '../../assets/img/products/tripsapp-screenshot.jpg'

class Projects extends React.Component {
    state = {

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    getMeta = () => {
        return (
            <Helmet>
                <title>Collaborative Travel Platform Projects | Luna Travel Technology</title>
                <meta name="description" content="Luna Travel Technology works with travel companies to create bespoke white label experiences for everything from luxury honeymoons to group travel for Marriott International." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <div className="page">
                {this.getMeta()}
                <div className="container-fluid">
                    <div className="row home-band back-dark">
                        <div className="col">
                            <h1>Luna Travel Projects</h1>
                            <p>With over 40,000 trips created across the Luna platform, our products provide tangible results everyday.</p>
                        </div>
                    </div>

                    <div className="row home-band">
                        <div className="col-sm-4 offset-sm-1">
                            <img src={lunaImg} width="400" alt="lunamoons.com screenshot"/>
                        </div>
                        <div className="col-sm-6 text-left">
                            <h2>LunaMoons.com</h2>
                            <h3>A wedding planner for your honeymoon</h3>
                            <p>
                                Launched in the spring of 2017, lunamoons.com was the project that started it all.
                                Specializing in "moons" of all kinds, the Lunamoons platform covers luxury travel for couples of all ages.
                            </p>
                            <a href="https://www.lunamoons.com/" target="_blank" className="btn btn-dark">
                                Visit LunaMoons.com
                            </a>
                        </div>
                    </div>

                    <div className="row home-band">
                        <div className="col-sm-4 offset-sm-1">
                            <img src={packImg} width="400" alt="lunamoons.com screenshot"/>
                        </div>
                        <div className="col-sm-6 text-left">
                            <h2>pack.spg.com</h2>
                            <h3>Multi-person vacation planning in 25 markets</h3>
                            <p>
                                As a collaboration with Marriott International, Luna launched a pilot project called Pack.
                                Taking group travel to a whole new level, this project focused on vacation planning in 25 markets in North America.
                            </p>
                            <a href="https://pack.spg.com/" target="_blank" className="btn btn-dark">
                                Try Pack
                            </a>
                        </div>
                    </div>

                    <div className="row home-band">
                        <div className="col-sm-4 offset-sm-1">
                            <img src={tripsbyImg} width="400" alt="lunamoons.com screenshot"/>
                        </div>
                        <div className="col-sm-6 text-left">
                            <h2>Trips By Luna</h2>
                            <h3>White Label Platform for collaborative travel planning</h3>
                            <p>
                                Leveraging the tech of Luna Travel Technology, Trips by Luna is a travel planning and booking solution for companies looking for a full-service platform for all their travel needs.
                            </p>
                            <a href="https://www.tripsbyluna.com/" target="_blank" className="btn btn-dark">
                                Explore Trips by Luna
                            </a>
                        </div>
                    </div>

                    <div className="row home-band">
                        <div className="col-sm-4 offset-sm-1">
                            <img src={tripsappImg} width="400" alt="lunamoons.com screenshot"/>
                        </div>
                        <div className="col-sm-6 text-left">
                            <h2>Trips.app</h2>
                            <h3>Run your own travel business</h3>
                            <p>
                                Turnkey business solution for professional travel advisors looking to optimize revenue, streamline client management, and bolster their online presence.
                            </p>
                            <p>
                                COMING SOON
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Projects
