import React from 'react'

class Footer extends React.Component {
    state = {

    }

    render = () => {
        return (
            <div className="container-fluid footer">
                <div className="row social">
                    <div className="col">
                        <span>Follow Us</span>
                        <div className="share-icons">
                            <a href="https://www.facebook.com/lunaplanner/" target="_blank" rel="noopener noreferrer">
                                <i className="shs-fb-2" />
                            </a>
                            <a href="https://www.instagram.com/mylunamoons/" target="_blank" rel="noopener noreferrer">
                                <i className="shs-insta" />
                            </a>
                            <a href="https://www.pinterest.com/lunalovestravel/" target="_blank" rel="noopener noreferrer">
                                <i className="shs-pin" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        &nbsp;
                    </div>
                    <div className="col-6 text-right">
                        &copy; 2018 Luna Travel Technology, Inc.
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
