import React from 'react'
import Helmet from 'react-helmet'

class Contact extends React.Component {
    state = {

    }

    getMeta = () => {
        return (
            <Helmet>
                <title>Contact Information for Luna Travel Technology</title>
                <meta name="description" content="Get in touch or a request a demo of our travel platform. See contact details provided." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <div className="page">
                {this.getMeta()}
                <div className="container-fluid">
                    <div className="row home-band back-dark">
                        <div className="col">
                            <h1>Contact Information</h1>
                        </div>
                    </div>
                    <div className="row home-band">
                        <div className="col-md-6 offset-md-3 text-left">
                            <h2>Built and Managed in Texas</h2>
                            <p>
                                Luna Travel Technology is headquartered in Austin, TX.
                            </p>
                            <p>
                                Address:<br />
                                Luna Travel Technology, Inc.<br />
                                701 Brazos St.<br />
                                Suite 1616<br />
                                Austin, TX 78701
                            </p>
                            <p>
                                hello@lunamoons.com<br />
                                (512) 766-7684
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact
