import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import navLogo from '../../assets/img/logos/luna-travel-tech-logo-white.svg'

const PAGES = ['features', 'projects', 'about', 'contact']

class Nav extends React.Component {
    state = {
        isOpen: false
    }

    getLogo = () => {
        if (this.props.location.pathname !== '/') {
            return <img src={navLogo} alt='Luna Travel Technology' width="110" className="pl-3" />
        }
        return null
    }

    getNavItems = () => {
        return PAGES.map((page, idx) => {
            return (
                <li key={idx} className="nav-item">
                    <Link className={`nav-link ${this.props.location.pathname === `/${page}/` ? 'active' : null}`} to={`/${page}/`}>
                        {page.replace('-', ' ')}
                    </Link>
                </li>
            )
        })
    }

    toggleNav = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    render = () => {
        let navClasses = classNames('navbar navbar-expand-lg w-100', { dark: this.props.location.pathname !== '/' })
        let colClasses = classNames('collapse navbar-collapse', { show: this.state.isOpen })
        let navBtnClasses = classNames('navbar-toggler', { active: this.state.isOpen })
        return (
            <nav className={navClasses}>
                <Link className="navbar-brand" to="/">
                    {this.getLogo()}
                </Link>
                <button className={navBtnClasses} type="button" aria-label="Toggle navigation" onClick={this.toggleNav}>
                    <span className="closeBtn ta-icon-bold ta-icon-close" />
                    <span className="navbar-toggler-icon ta-icon-bold ta-icon-nav_a" />
                </button>
                <div className={colClasses} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        {this.getNavItems()}
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withRouter(Nav)