import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import lttLogo from '../../assets/img/logos/luna-travel-tech-logo-white.svg'
import laptop from '../../assets/img/computer-mountains.svg'
import bgImg from '../../assets/img/luna-travel-tech-background.jpg'

class Home extends React.Component {
    state = {

    }

    getMeta = () => {
        return (
            <Helmet>
                <title>Luna Travel Technology Collaborative Platform</title>
                <meta name="description" content="Luna Travel is a collaborative travel planning and booking platform. Our interactive itinerary helps people make better informed travel plans." />
            </Helmet>
        )
    }

    render = () => {
        return (
            <div>
                {this.getMeta()}
                <div className="billboard" style={{ backgroundImage: `url(${bgImg})` }}>
                    <div className="container">
                        <div className="row">
                            <img src={lttLogo} className="logo" alt='Luna Travel Technology Logo' />
                            <h1>Collaborative travel planning and booking platform</h1>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row home-band back-dark">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                            <img src={laptop} className="icon-img" alt="laptop graphic icon" width="125" />
                            <h2>Travel Platform</h2>
                            <p>
                                Luna Travel Technology is a collaborative travel planning and booking platform.
                                Offering a full-spectrum of travel solutions, our products help people travel together better.
                                By bringing together the people, places, and resources that inform travel plans, our solutions are able to cover every aspect of the itinerary.
                                The Luna Travel Technology portfolio of solutions covers every business and personal need when it comes to travel planning from the Enterprise hotelier to the at-home travel agent. 
                            </p>
                        </div>
                    </div>

                    <div className="row home-band back-light">
                        <div className="col-sm-6 offset-sm-3">
                            <h2>Travel Features</h2>
                            <p>
                                Luna's platform covers the entire itinerary &mdash;
                                from where to go, where to stay, what to do and how to get around.
                                Backed by our integrated booking engine, we offer the only interactive itinerary with booking capabilities.
                            </p>
                            <Link to="/features/" className="btn btn-dark">See All Features</Link>
                        </div>
                    </div>

                    <div className="row home-band back-light">
                        <div className="col-sm-6 offset-sm-3">
                            <h2>Schedule a Demo</h2>
                            <p>
                                Want to learn more and see our technology in action?
                                Click the button below to schedule a demo today.
                            </p>
                            <a href="https://calendly.com/luna-moons/luna-travel-tech-demo" target="_blank" rel="noopener noreferrer" className="btn btn-dark">Request a Demo</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home
