import React from 'react'

class Uses extends React.Component {
    state = {

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render = () => {
        return (
            <div className="page">
                <div className="container-fluid">
                    <div className="row home-band back-light">
                        <div className="col">
                            <h2>I should be an H1 for Use Cases</h2>
                            <p>Hello there</p>
                        </div>
                    </div>
                    <div className="row home-band">
                        <div className="col-md-6 offset-md-3 text-left">
                            <h2>Collaborative Travel Planning and Booking Platform</h2>
                            <p>
                                Luna Travel Technology is a collaborative travel planning and booking platform.
                                Our products help travelers make better informed purchases by activating the people, places and resources that inform travel plans.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Uses
